/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    android2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M10.213 1.471l.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 008.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 00-1.673 1.476A3.7 3.7 0 003.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 00-1.664-1.476zM6.22 3.303a.37.37 0 01-.267.11.35.35 0 01-.263-.11.37.37 0 01-.107-.264.37.37 0 01.107-.265.35.35 0 01.263-.11q.155 0 .267.11a.36.36 0 01.112.265.36.36 0 01-.112.264m4.101 0a.35.35 0 01-.262.11.37.37 0 01-.268-.11.36.36 0 01-.112-.264q0-.154.112-.265a.37.37 0 01.268-.11q.155 0 .262.11a.37.37 0 01.107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 00.7.288.97.97 0 00.71-.288.95.95 0 00.292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 00.71.288.97.97 0 00.71-.288.95.95 0 00.292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 00-.702.278.9.9 0 00-.293.685v4.063q0 .406.293.69a.97.97 0 00.702.284q.42 0 .712-.284a.92.92 0 00.293-.69V6.146a.9.9 0 00-.293-.685 1 1 0 00-.712-.278m-12.702.283a1 1 0 01.712-.283q.41 0 .702.283a.9.9 0 01.293.68v4.063a.93.93 0 01-.288.69.97.97 0 01-.707.284 1 1 0 01-.712-.284.92.92 0 01-.293-.69V6.146q0-.396.293-.68"/>',
    },
});
